<template>
	<div class="packaging-body">
		<Form :label-width="80" label-colon inline>
			<FormItem label="申请时间">
				<DatePicker v-model="search.apply_time" type="daterange" placement="bottom" placeholder="请选择时间" style="width: 200px"></DatePicker>
			</FormItem>
			<FormItem label="申请状态">
				<RadioGroup v-model="search.status" type="button" button-style="solid">
					<Radio :label="-1">全部</Radio>
					<Radio :label="0">审核中</Radio>
					<Radio :label="1">已同意</Radio>
					<Radio :label="2">已拒绝</Radio>
				</RadioGroup>
			</FormItem>
			<FormItem :label-width="20">
				<Button type="primary" @click="init()">搜索</Button>
			</FormItem>
		</Form>
		<Table :columns="columns" :data="data" :loading="loading">
			<template slot="apply_status" slot-scope="{row}">
				<Tag :color="row.status ? (row.status == 1 ? 'success' : 'error') : 'default'">{{ statusArr[row.status] }}</Tag>
			</template>
			<template slot="baling_config" slot-scope="{row}">
				<span class="cr0ff" @click="show_config(row.baling_config)">查看配置</span>
			</template>
			<template slot="operate" slot-scope="{row}">
				<Button style="margin-right: 8px;" @click="consent_baling(row.id)" v-if="row.status == 0">同意打包</Button>
				<Button @click="refuse_baling(row.id)" v-if="row.status == 0">拒绝打包</Button>
			</template>
		</Table>
		<div class="page-r">
			<Page :total="search.total" :current="search.page" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-sizer
			@on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
		</div>
		<Modal v-model="config.show" title="打包配置" width="700" footer-hide>
			<Row v-for="(item, index) in config.formData" :key="index" class="config-line">
				<Col :span="8" class="line-title">{{ item.name }}：</Col>
				<Col :span="16" style="height: 46px;">
					<div v-if="item.name == '图标配置'" style="height: 46px;">
						<img :src="'https://'+item.value" style="width: 46px; height: 46px;">
					</div>
					<div v-else>{{ item.value || '无' }}</div>
				</Col>
			</Row>
		</Modal>
		<Modal v-model="operate_modal.show" title="打包配置" width="500">
			<Form :label-width="80" label-colon>
				<FormItem label="审核状态">
					<Tag size="medium" :color="operate_modal.form.status == 1 ? 'success' : 'error'">{{ operate_modal.form.status == 1 ? '同意打包' : '拒绝打包' }}</Tag>
				</FormItem>
				<FormItem label="说明原因" v-if="operate_modal.form.status == 2">
					<Input type="textarea" v-model="operate_modal.form.feedback_info" :rows="4" placeholder="请输入拒绝的理由"></Input>
				</FormItem>
				<FormItem label="应用包">
					<Uploads class="fi-upload" @upload_succ="uploads_succ" :action="ApiUrl+'/adm/upload_object'" :format="['apk']" accept="*">
						<div slot="custom" style="width: 100px;">
							<Icon type="md-cloud-upload" size="16" />
							<span style="font-size: 12px;">上传应用包</span>
						</div>
					</Uploads>
					<Tooltip :content="operate_modal.form.download_link" max-width="800">
						<div class="uploads-path">{{ operate_modal.form.download_link }}</div>
					</Tooltip>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="text" @click="operate_modal.show = false">取消</Button>
				<Button type="primary" @click="operateConfirm">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	var _this;
	import Uploads from '../../../components/common/Uploads';
	export default {
		name: 'PackagingList',
		components: { Uploads },
		data () {
			return {
				loading: false, // 加载中
				search: {
					apply_time: [], // 申请时间
					status: -1, // 申请状态
					total: 0, // 分页-总数
					page: 1, // 分页-当前页码
					pageSize: 15 // 分页-每页条数
				},
				columns: [
					{ title: 'ID', key: 'id', minWidth: 90 },
					{ title: '申请时间', key: 'apply_time', minWidth: 160 },
					{ title: '申请状态', slot: 'apply_status', minWidth: 200 },
					{ title: '打包配置', slot: 'baling_config', minWidth: 200 },
					{ title: '反馈信息', key: 'feedback_info', minWidth: 200 },
					{ title: '操作', slot: 'operate', minWidth: 200 }
				], // 表头
				data: [],
				statusArr: ['审核中','已同意','已拒绝'], // 申请状态数组
				config: {
					show: false, // 查看配置-显示状态
					formData: [] // 查看配置-配置数据
				},
				operate_modal: {
					show: false, // 操作-显示状态
					form: {
						id: 0,
						status: 0,
						feedback_info: '',
						download_link: ''
					}
				}
			}
		},
		created () {
			_this = this;
			this.init();
		},
		methods: {
			// 初始化
			init () {
				this.loading = true;
				this.requestApi('/adm/get_app_baling_list',{ search: this.search }).then((res)=>{
					if(res.status == 1){
						_this.data = res.data.data;
						_this.search.total = res.data.total;
						_this.loading = false;
					}
				})
			},
			// 页码改变
			pageChange (page) {
				this.search.page = page;
				this.init();
			},
			// 每页条数改变
			pageSizeChange (pageSize) {
				this.search.pageSize = pageSize;
				this.init();
			},
			// 查看配置
			show_config (config) {
				this.config.show = true;
				this.config.formData = JSON.parse(JSON.stringify(config));
			},
			// 同意打包
			consent_baling (id) {
				this.operate_modal.form.id = id;
				this.operate_modal.form.status = 1;
				this.operate_modal.show = true;
			},
			// 拒绝打包
			refuse_baling (id) {
				this.operate_modal.form.id = id;
				this.operate_modal.form.status = 2;
				this.operate_modal.show = true;
			},
			// 确认操作
			operateConfirm () {
				this.requestApi('/adm/edit_app_baling_info',{ data: this.operate_modal.form }).then((res)=>{
					if(res.status == 1){
						_this.operate_modal = false;
						_this.alertSucc('操作成功');
						_this.init();
					}
				})
			},
			// 上传回调
			uploads_succ (res) {
				if(res.status){
					this.operate_modal.form.download_link = 'https://'+res.pic_url
				}
			}
		}
	}
</script>

<style scoped>
	.packaging-body {
		padding: 16px;
		background: #fff;
	}
	.page-r{
		padding-top: 16px;
	}
	.cr0ff{
		color: #1890ff;
		cursor: pointer;
	}
	.config-line {
		line-height: 46px;
		height: 46px;
	}
	.line-title {
		font-weight: 700;
		text-align: right;
	}
	.fi-upload {
		width: max-content;
		box-sizing: border-box;
	}
	.uploads-path{
		width: 350px;
		overflow: hidden;		
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>